import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// importing components
import Img from "gatsby-image"

// importing assets
import "./proverb-section.scss"
import SlideInAnimation from "../../utilities/SlideInAnimation/slideInAnimation"

const ProverbSection = () => {
  const data = useStaticQuery(graphql`
    query {
      splashwaterImage: file(relativePath: { eq: "splashwater1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="proverb-section">
      <div className="water-image">
        <Img fluid={data.splashwaterImage.childImageSharp.fluid} />
        {/* <img src={splashImg} alt='splashwater'></img> */}
      </div>
      <div className="quote-modal">
        <div>
          <p>“Quality is not an act, it’s Aquis…”</p>
          <p>- Donesh Jain, CEO & MD</p>
        </div>
      </div>
    </div>
  )
}

export default ProverbSection
