import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// importing componenets
import CardStrip from "../../utilities/CardStrip/cardStrip"

const ShortDesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      doneshImage: file(relativePath: { eq: "donesh-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hingesImage: file(relativePath: { eq: "hinges.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      treeImage: file(relativePath: { eq: "tree.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const dataArr = [
    {
      title: `Who are we?`,
      description:
        "AQUIS, established in 2014, by Donesh, is a leading manufacturer of Brass Hinges & Architectural hardware conforming to the accepted international standards. Our main goal is to satisfy our end users with high quality products.",
      gatsbyImg: data.doneshImage.childImageSharp.fluid,
      buttonTo: "/about",
      buttonText: "Know more",
      backgroundColor: "#058080",
    },
    {
      title: `Products`,
      description:
        "All of our products are assured to be of high quality. We ourselves strictly supervise all the activity of manufacturing right from importing of the brass material to the full-fledged finished product.",
      gatsbyImg: data.hingesImage.childImageSharp.fluid,
      buttonTo: "/products",
      buttonText: "Explore all items",
      backgroundColor: "#fff",
    },
    {
      title: `Reach out`,
      description:
        "We provide top notch customer service to all our valued customer. Customers can reach out to us by clicking the button below and we will make sure we will get back to you as soon as possible.",
      gatsbyImg: data.treeImage.childImageSharp.fluid,
      buttonTo: "/contact",
      buttonText: "Contact Us",
      backgroundColor: "#058080",
    },
  ]
  return (
    <div>
      {dataArr.map((el, ind) => {
        return (
          <CardStrip
            key={ind}
            backgroundColor={el.backgroundColor}
            gatsbyImg={el.gatsbyImg}
            title={el.title}
            description={el.description}
            buttonTo={el.buttonTo}
            buttonText={el.buttonText}
          />
        )
      })}
    </div>
  )
}

export default ShortDesSection
