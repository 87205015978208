import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// importing components
import Img from "gatsby-image"
import Button from "../../utilities/Button/button"
import ScrollDown from "../../utilities/ScrollDown/scrollDown"

// importing assets
import "./main-landing.scss"
import SlideInAnimation from "../../utilities/SlideInAnimation/slideInAnimation"

const MainLanding = () => {
  const data = useStaticQuery(graphql`
    query {
      doorImage: file(relativePath: { eq: "door.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hingesImage: file(relativePath: { eq: "hinges.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      treeImage: file(relativePath: { eq: "tree.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <SlideInAnimation>
      <div className="main-landing">
        <div className="container">
          <div className="heading">
            Modern
            <br />
            Style &
            <br />
            YOU!
            <Button name="Explore all items" to="/products" />
          </div>
          <div className="images-container">
            <div className="door-image">
              <Img fluid={data.doorImage.childImageSharp.fluid} />
            </div>
            <div className="hinges-image">
              <Img fluid={data.hingesImage.childImageSharp.fluid} />
            </div>
            <div className="tree-image">
              <Img fluid={data.treeImage.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <ScrollDown href="/#proverb-section" />
      </div>
    </SlideInAnimation>
  )
}

export default MainLanding
