import React from "react"
import Layout from "../components/layout"
import MainLanding from "../components/home/MainLandingSection/mainLanding"
import ProverbSection from "../components/home/ProverbSection/proverbSection"
import ShortDesSection from "../components/home/ShortDesSection/shortDesSection"
import SupportBrands from "../components/utilities/SupportBrands/supportBrands"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainLanding />
    <ProverbSection />
    <ShortDesSection />
    <SupportBrands />
  </Layout>
)

export default IndexPage
